/* Custom CSS for code block background */
.custom-md-editor pre {
    background-color: #1F233E !important;
    /* Custom background color */
    color: #ffffff !important;
    /* Custom text color */
    padding: 10px !important;
    /* Padding inside the code block */
    border-radius: 5px !important;
    /* Rounded corners */
    overflow-x: auto !important;
    /* Horizontal scrolling if needed */
}

/* .custom-md-editor .token.class-name {
    color: ""
} */


.custom-md-editor pre .copied {
    background-color: transparent;
    transition: background-color 0.3s ease;
    color: rgba(255, 255, 255, 0.6) !important
        /* Smooth transition for color changes */
}

/* Change color on hover */
.custom-md-editor pre .copied:hover {
    background-color: transparent !important;
    color: rgba(255, 255, 255) !important
}

.custom-md-editor pre .copied:active {
    color: "white" !important;
    transition: background-color 0.3s ease;
}

/* Change color on click (active state) */
.custom-md-editor pre .copied:active,
.custom-md-editor pre .copied:focus {
    background-color: transparent !important;
    color: white !important;
    outline: none;
    transition: background-color 0.3s ease;
    /* Remove default outline */
}

/* Ensure color stays after the pointer is removed */
.custom-md-editor pre .copied:focus:not(:hover) {
    background-color: transparent !important;
    color: white !important;
    transition: background-color 0.3s ease;
}

.custom-md-editor pre .copied.active {
    background-color: transparent;
    transition: background-color 0.3s ease;
    /* Smooth transition for color changes */
}

.custom-md-editor .token.keyword {
    color: #2e95d3;
}

.wmde-markdown .token.maybe-class-name,
.wmde-markdown .token.id {
    color: #f22c3d;
}

.wmde-markdown .token.class-name,
.wmde-markdown .token.operator,
.wmde-markdown .token.function,
.wmde-markdown .token.tag,
.wmde-markdown .token.selector,
.wmde-markdown .token.punctuation.attr-equals {
    color: white
}

.wmde-markdown table td,
.wmde-markdown table th {
    color: #1F233E
}

.wmde-markdown .token.property,
.wmde-markdown .token.string,
.wmde-markdown .token.property-access,
.wmde-markdown .token.attr-value {
    color: #00a67d !important;
}

.wmde-markdown .token.color,
.wmde-markdown .token.number,
.wmde-markdown .token.unit,
.wmde-markdown .token.class,
.wmde-markdown .token.attr-name,
.wmde-markdown .token.literal-property.property {
    color: #df3079 !important;
}

.wmde-markdown .token.comment {
    color: #6a737d
}



/* .wmde-markdown .token .punctuation */
/* #f22c3d */
